import { mapState } from "vuex";
import ProducReasons from "../views/components/template_product_reasons.vue";
import AnnualReportServices from "../../data/services/annual_report_services";
import ErrorMessaje from "../../../../core/error/error_messaje";
import validateInputs from "../../../../core/utils/validate_inputs";
import cero_detail from "@/feature/annual_report/presentation/views/components/step0_detail/cero_detail";


export default {
  components: {
      cero_detail,
      ProducReasons
  },
  data() {
    return {
      legend: "",
      loading: false,
      disabled: false,
      annual_report: {
        company: {
          sfc_code: "",
          company_id: "",
          company_name: "",
        },
        ar_tipo: null,
        pediodo_inicio: "",
        periodo_final: "",
        fecha_reporte: "",
        justificacion_parcial: "",
      },
      optionsType: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Parcial" },
      ],
    };
  },
  methods: {
    goToReportList() {
      this.$router.push({ 'name': 'annual-report-list' }
      )
    },
    validateDates() {
      const childComponentRef = this.$refs.detalleCero.$refs;
      let isValid = validateInputs.validate([
        {
          data: this.annual_report.company.company_name,
          ref: childComponentRef["entity"],
        },
        {
          data: this.annual_report.pediodo_inicio,
          ref: childComponentRef["date_init"],
        },
        { 
          data: this.annual_report.periodo_final, 
          ref: childComponentRef["date_end"] 
        },
        {
          data: this.annual_report.ar_tipo,
          ref: childComponentRef["ar_type"],
        },
      ]);

      if (isValid) {
        let fechaIni = new Date(this.annual_report.pediodo_inicio);
        let fechaFin = new Date(this.annual_report.periodo_final);

        if (fechaFin < fechaIni) {
          this.$notification.warning({
            message: "El período final no puede ser anterior al período inicio",
          });

          return false;
        }
      }

      return isValid;
    },
    async createAnualReportPRUEBA(){
      this.$router.push({
        name: "annual-report-edit",
        params: { id: "106e1138-23d6-44b8-9edf-c2dafa5346d3", showStepDetail: null },
      });
    },
    async createAnnualReport() {
      this.annual_report.responsable = this.current_user.first_name + ' ' + this.current_user.last_name,
      this.annual_report.responsable_id = this.current_user.id
      if (this.validateDates()) {
        let data = await AnnualReportServices.createAnualReport(
          this.annual_report
        );

        if (!data.isSuccess) {
          this.$notification.error({
            message: ErrorMessaje.errorTitle,
            description: data.message,
          });
        } else {
          console.log(data.data)
          this.$router.push({
            name: "annual-report-edit",
            params: { id: data.data.document_id, showStepDetail: null },
          });

        }
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    cleanForm() {
      this.disabled = false;
    },
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
    formOptions: function () {
      return this.$store.state.form;
    },
    current_user: function () {
      return this.$store.state.session.user;
    },
  },
};
