<template>
    <div>
        <div class="
            w-full
            max-w-5xl
            grid
            grid grid-cols-2
            gap-y-6
            gap-x-10
            justify-end
            items-end
          ">
            <BaseSelect ref="entity" id="entity" label="Selecciona entidad vigilada" @change="onChangeSelect"
                :selectOptions="formOptions.optionsCompanies" required="true" :getObjectValue="true" :disabled="disabledElements" 
                v-model="annual_report.company.company_id" />

            <BaseInput ref="code_entity" type="text" label="Código de la entidad" :disabled="true" placeholder="Ej: 123"
                :required="false" v-model="annual_report.company.sfc_code" />

            <BaseRadio ref="ar_type" name="ar_type" label="Tipo de informe" @onChangeValueGetValue="onChangeRadio"
                :radioOptions="optionsType" required="true" class="col-end-2 col-span-1" :disabled="disabledElements" 
                v-model="annual_report.ar_tipo" />

            <BaseDate ref="date_init" id="date-input" label="Período inicio" :required="true" type="text" :disabled="disabledElements" 
                placeholder="DD/MM/AAAA" class="col-start-1" v-model="annual_report.pediodo_inicio" />

            <BaseDate ref="date_end" id="date-input" label="Período final" :required="true" type="text"
                placeholder="DD/MM/AAAA" v-model="annual_report.periodo_final" :disabled="disabledElements" />

            <BaseDate ref="date_report" id="date-input" label="Período de registro de informe" :required="true"
                type="text" placeholder="DD/MM/AAAA" class="col-end-2 col-span-1" v-model="annual_report.fecha_reporte"
                :disabled="true" />

            <BaseTextarea v-if="annual_report.ar_tipo == 2" class="col-start-1 col-span-2" ref="describe" id="describe"
                v-model="annual_report.justificacion_parcial" label="Justificación del informe parcial"
                placeholder="Mensaje" maxlength="500" :disabled="disabledElements" />
        </div>

    </div>
</template>


<script >
import { mapState } from "vuex";
import moment from "moment";


export default {
    props: {
        annual_report: {
            type: Object,
            default: null,
        },
        disabledElements: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            disabled_date_report: false,
            optionsType: [
                { id: 1, name: "Anual" },
                { id: 2, name: "Parcial" },
            ],
        }
    },
    methods:{
        onChangeSelect(value) {
            console.log("onChangeSelect", value);
            this.annual_report.company.sfc_code = value.sfc_code.toString();
            this.annual_report.company.company_name = value.name.toString();
        },
        onChangeRadio(value) {
            this.annual_report.fecha_reporte = moment();
        },
    },
    computed: {
        ...mapState({
            formOptions: (state) => {
                return state.form;
            },
        }),
        formOptions: function () {
            return this.$store.state.form;
        },
    },
};

</script>