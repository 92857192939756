import request from "../../../../core/data/network/client/request";
import Api from "../../../../core/data/network/constants/api_routes";

export default {
  getAnualReport: async (document_id) => {
    return request.get(Api.getAnualReport(document_id));
  },
  createAnualReport: async (dataReport) => {
    return request.post(Api.createAnualReport, dataReport);
  },
  updateAnualReport: async (dataReport, document_id) => {
    return request.patch(Api.updateAnualReport(document_id), dataReport);
  },
  getCounters: async (data,query) => {
    return request.post(Api.getCounters(query),data);
  },
  listAnualReports: async (query) => {
    return request.get(Api.listAnualReports(query));
  },
  updateAnualReportFiles: async (formData) => {
    return request.post(Api.updateAnualReportFiles, formData);
  },
};
