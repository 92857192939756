import AnnualReportServicesImpl from "./annual_report_services_impl";

export default {
  getAnualReport: async (document_id) => {
    return AnnualReportServicesImpl.getAnualReport(document_id);
  },
  createAnualReport: async (dataReport) => {
    return AnnualReportServicesImpl.createAnualReport(dataReport);
  },
  updateAnualReport: async (dataReport, document_id) => {
    return AnnualReportServicesImpl.updateAnualReport(dataReport, document_id);
  },
  getCounters: async (data,query) => {
    return AnnualReportServicesImpl.getCounters(data,query);
  },
  listAnualReports: async (query) => {
    return AnnualReportServicesImpl.listAnualReports(query);
  },
  updateAnualReportFiles: async (formData) => {
    return AnnualReportServicesImpl.updateAnualReportFiles(formData);
  },
};
